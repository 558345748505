import { EventStatus, StbW2023WkMode } from '@/enum'

export const useCalc = () => {
  const istSprung = (did) => did === 'fc78fdd9-bb80-4be4-8fb3-498a150c92b6'

  const beginn = (e) => {
    return e?.startDate
  }

  const halle = (e) => {
    if (e?.venue?.name) return `${e?.venue?.name}, ${e?.venue?.address?.addressLocality}`
    return e?.venue?.address?.addressLocality
  }

  const modus = (e) => {
    return StbW2023WkMode.find(m => m.value === e.mode)?.text || e.mode
  }

  const status = (e) => {
    return EventStatus.find(m => m.value === e.status)?.text || e.status
  }

  const maxturner = (e) => {
    switch (e.mode) {
      case 'wk_4aus5':
      case 'wk_3aus5':
        return 5
    }
    return 0
  }

  const wertungsturner = (e) => {
    switch (e.mode) {
      case 'wk_3aus5':
        return 3
      case 'wk_4aus5':
        return 4
    }
    return 0
  }

  const geraete = (e, df, all) => {
    return e?.disciplines?.map(d => ({
      ...d,
      name: df?.find(d2 => d2._id === d._discipline)?.name,
      shortName: df?.find(d2 => d2._id === d._discipline)?.shortName,
      regular: df?.find(d2 => d2._id === d._discipline)?.regular
    }))?.filter(d => all || d.regular)?.sort((a, b) => a.order < b.order ? -1 : 1) || []
  }

  const geraetnr = (e, df, did) => {
    return geraete(e, df).findIndex(d => d._discipline === did)
  }

  const mannschaftsgeraetefolge = (e, df, tid) => {
    const disc = geraete(e, df)?.sort((a, b) => a.order < b.order ? -1 : 1) || []
    const start = e?.teams?.find(t => t.team._id === tid)?._startdiscipline

    const index = disc.findIndex(d => d._discipline === start)

    if (index === -1) return disc
    const tmp = disc.splice(0, index)
    return [...disc, ...tmp]
  }

  const mannschaftenangeraet = (e, df, did) => {
    const t = e?.teams?.slice()
      ?.map(t => ({
        ...t,
        geraetefolge: mannschaftsgeraetefolge(e, df, t.team._id),
        did,
        df: mannschaftsgeraetefolge(e, df, t.team._id).findIndex(d => d._discipline === did)
      }))
      ?.sort((a, b) => {
        if (a.df !== b.df) return a.df < b.df ? -1 : 1
        return a.order < b.order ? -1 : 1
      })
    if (!t || t.length === 0) return []

    return t
  }

  const mannschaftgeraetergebnis = (e, r, tid, did) => {
    const turner = r?.filter(r => r._discipline === did && r._team === tid && r.subpart === null) || []
    return turner
      .map(t => parseFloat(t.final) || 0)
      .sort((a, b) => a > b ? -1 : 1)
      .slice(0, wertungsturner(e))
      .reduce((acc, curr) => acc + curr, 0)
  }

  const alleturnerangeraet = (e, r, df, did) => {
    return mannschaftenangeraet(e, df, did).reduce((acc, curr) => {
      acc.push(...mannschaftturnerangeraet(e, r, curr.team._id, did))
      return acc
    }, [])
  }

  const mannschaftturnerangeraet = (e, r, tid, did, nr) => {
    const turner = r?.filter(r => r._discipline === did && r._team === tid && r.subpart === null) || []
    const tmp = turner
      .map(t => ({
        person: t.person,
        team: t.team,
        order: t.order,
        result: t,
        subparts: r?.filter(r => r._discipline === did && r._team === tid && r._person === t.person._id && r.subpart !== null)
      }))
      ?.sort((a, b) => a.result.order < b.result.order ? -1 : 1) || []
    return nr !== undefined ? tmp[nr - 1] : tmp
  }

  const mannschaftturnerangeraetfertig = (e, r, tid, did) => {
    return mannschaftturnerangeraet(e, r, tid, did).filter(t => t.result.final !== null)
  }

  const mannschaftgesamtergebnis = (e, r, tid) => {
    const team = e.teams.find(t => t.team._id === tid)
    const result = team.results?.find(r => !r._discipline)
    const penalty = result?.penalty || 0
    return Math.round(e.disciplines.reduce((acc, curr) => acc + mannschaftgeraetergebnis(e, r, tid, curr._discipline), -penalty) * 100) / 100
  }

  const mannschaftgesamtergebnistp = (e, r, tid) => {
    const t = e.teams || []

    const res = t.map(t => ({
      _team: t.team._id,
      final: mannschaftgesamtergebnis(e, r, t.team._id)
    }))

    const own = res.find(r => r._team === tid)
    const other = res.filter(r => r._team !== tid)

    if (own === undefined || own.final < 0.01) return 0
    return other.reduce((acc, curr) => {
      // if (Math.abs(own.final - curr.final) < 0.01) return acc + 1
      return acc + (own.final - curr.final > -0.01 ? 1 : 0)
    }, 1)
  }

  const geraet = (df, did) => {
    return df?.find(d => d._id === did) || {}
  }

  const kari = (e, did, type) => {
    return e?.judges?.find(j => j.type === type && j.person !== null && j._discipline === did)
  }

  const subpart = (r, did, pid, subpart) => {
    return r?.find(r => r._discipline === did && r._person === pid && r.subpart === subpart)
  }

  const getTabs = (es, df) => {
    const tabs = []

    for (const id in es) {
      if (es[id].status !== 'EventFinished' && es[id].status !== 'EventConfirmed') {
        tabs.push({ route: 'startgeraet', title: 'Startgeraet', hidden: es[id].status === 'EventStarted' })
        tabs.push({ route: 'turner', title: 'Letzte Turnerinnen', hidden: es[id].status !== 'EventStarted', time: 30000 })
        tabs.push({ id, route: 'uebersicht', title: `${es[id].parent.name}: Übersicht`, hidden: es[id].status !== 'EventStarted', time: 10000 })
        tabs.push(...geraete(es[id], df).map(d => ({
          did: d._discipline,
          route: 'geraet',
          title: d.name,
          hidden: true
        })))
        tabs.push({ id, route: 'finished', title: `${es[id].parent.name}: Mannschaften`, hidden: true })
      } else {
        tabs.push({ id, route: 'finished', title: `${es[id].parent.name}: Mannschaften` })
        tabs.push({ id, route: 'uebersicht', title: `${es[id].parent.name}: Übersicht` })
      }
    }

    return tabs
  }

  return {
    istSprung,
    beginn,
    halle,
    modus,
    status,
    maxturner,
    wertungsturner,
    geraete,
    geraetnr,
    mannschaftenangeraet,
    mannschaftgeraetergebnis,
    mannschaftturnerangeraet,
    mannschaftturnerangeraetfertig,
    mannschaftgesamtergebnis,
    mannschaftgesamtergebnistp,
    geraet,
    kari,
    alleturnerangeraet,
    subpart,
    getTabs
  }
}
