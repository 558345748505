<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      :mobile-breakpoint="-1"
      sort-by="final"
      sort-desc
    >
      <template #item="{item}">
        <tr>
          <td>{{ item.place }}</td>
          <td>{{ item.tp }}</td>
          <td>{{ item.team }}</td>
          <td v-for="d in geraete(e, df)" :key="d._discipline" style="text-align:center;">{{ item[d._discipline].final | float2 }} <i style="font-size: 60%;">( {{ item[d._discipline].fertig }}/{{ item[d._discipline].anz }})</i></td>
          <td style="text-align:center;"><b style="font-size: 150%;">{{ item.final | float2_0 }}</b></td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'

export default {
  name: 'zwischenstand',

  setup (props, context) {
    return {
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Platz', value: 'place', sortable: false },
        { text: 'TP', value: 'tp', sortable: false },
        { text: 'Mannschaft', value: 'team', sortable: false },
        ...this.geraete(this.e, this.df).map(d => ({ text: d.shortName, value: `${d._discipline}.final`, align: 'center' })),
        { text: 'Gesamtpunkte', value: 'final', align: 'center' }
      ]
    },
    items () {
      return (this.e.teams?.map(t => ({
        place: 0,
        tp: this.mannschaftgesamtergebnistp(this.e, this.r, t.team._id),
        team: t.team.name,
        ...this.geraete(this.e, this.df).reduce((acc, curr) => {
          acc[curr._discipline] = {
            anz: this.mannschaftturnerangeraet(this.e, this.r, t.team._id, curr._discipline).length,
            fertig: this.mannschaftturnerangeraetfertig(this.e, this.r, t.team._id, curr._discipline).length,
            final: this.mannschaftgeraetergebnis(this.e, this.r, t.team._id, curr._discipline)
          }
          return acc
        }, {}),
        final: this.mannschaftgesamtergebnis(this.e, this.r, t.team._id)
      })) || []).sort((a, b) => a.final < b.final ? 1 : -1).map((t, i, arr) => ({
        ...t,
        place: arr.filter(t2 => t2.final > t.final).length + 1
      }))
    }
  }
}
</script>

<style scoped>

</style>
